.tab-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  background-color: #4e9cdc;
}

.tab-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding: 0 25px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tab-container::-webkit-scrollbar {
  display: none;
}

.tab {
  margin-right: 10px;
  padding: 14px;
  font: $title 600medium; 
  color: $tab-text-color;
  white-space: nowrap;
  cursor: pointer;
}

.tab:last-of-type {
  margin-right: 0;
}

.active-tab {
  font-weight: 700;
  color: white;
  background-color: #017cdf;
}

.tab-content-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.tab-prev-next-button {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 5px;
  font-size: 30px;
  max-width: 25px;
  color: #ffffff;
  background-color: #40a1ef;
  cursor: pointer;
  outline: none;
  z-index: 20;
}

.tab-prev-button {
  left: 0;
  border-right: 1px solid $tab-text-color;
}

.tab-next-button {
  right: 0;
  border-left: 1px solid $tab-text-color;
}

.tab-prev-next-button:disabled {
  color: #6c7478;
  background-color: #2f3a3f;
}

.dummy-tab-div {
  height: 50px;
  width: 30px;
  padding: 0 10px;
}
