.accordion-container {
  border-radius: 5px;
}

.accordion-item {
  margin-bottom: 14px;
  border-radius: 6px;
  background-color: white;
}

.accordion-item:last-child {
  margin-bottom: 0;
}

.accordion-item-header-container {
  display: grid;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  grid-template-columns: 25% calc(65% - 20px) 10%;
  padding: 1px 5px 1px 1px;
  min-height: 8vh;
  color: $secondary-color;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;

  > div:first-child {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 24px;
    font-weight: 500;
    transition: transform .3s ease-in-out;
  }

  label {
    font-size: 14px;
    font-weight: 600
  }
}

.accordion-header-image {
  display: flex;
  align-items: center;
  height: 8vh;
  overflow: hidden;

  img {
  //  width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    background-color: white;
    border-radius: 6px;
  }
}

.accordion-item-selected .accordion-item-header-container {
  background-color: $background-color;
}

.accordion-item-header-container:hover {
  background-color: #DBE3EC;
}

.accordion-body-container {
  max-height: 0;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
  transition: all .5s ease-in-out;

    >*:first-child {
      margin-top: 10px;
    }
}

.active-accordion {
  max-height: 2000px;
}

.accordion-body-container.active-accordion {
  overflow: unset!important;
  > div:last-child {
    padding-bottom : 10px;
  }
}

.rotate-icon {
  transform: rotate(180deg);
}

.accordion-item-count {
  margin-left: 10px;
  padding: 5px;
  font-size: $subtitle!important;
  color: white;
  background-color: $primary-color;
  border-radius: 5px;
}
