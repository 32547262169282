.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width:100%;
  padding: 20px;
  background-color: white;
  background-blend-mode: screen;
  z-index: 11;
}

.wine-glass path {
    fill: $primary-color;
    color: $primary-color;
    background-color: $primary-color;
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 40px 40px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-striped {
  overflow: hidden;
  height: 10px;
  width: 70%;
  margin-top: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.progress-bar-striped > div {
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 3s ease;
  -moz-transition: width 3s ease;
  -o-transition: width 3s ease;
  transition: width 3s ease;
  animation: progress-bar-stripes 2s linear infinite;
  background-color: $secondary-color;
}

@keyframes progress-bar-stripes {

  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}
