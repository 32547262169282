@import 'style/style';
@import 'pages/pages';
@import 'src/components/component';

body{
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

::placeholder {
  font-size: 12px;
  color: $placeholder-color;
}

.toast-notification {
  width: 90% !important;
  span {
    padding: 10px 20px!important;
  }
}
