.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .25);
  background-blend-mode: screen;
  z-index: 30;
}

.modal-content {
  max-height: 80vh;
  width: 95vw;
  background-color: white;
  border-radius: .3rem;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  font-size: 24px;
  font-weight: 600;
  color: $secondary-color;
  border-bottom: 1px solid $border-color;

  .material-icons-round {
    color: darkgray;
    cursor: pointer;
  }
}

.modal-body {
  padding: 10px 10px;
  overflow-y: auto;

  tr:hover {
    background-color: $background-color
  }
}

.modal-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 30px;
  border: 1px solid $border-color;
  border-radius: 0 0 .3rem .3rem;
}
