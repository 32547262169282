$primary-color: #dd4b39;
$primary-variant-color: #f0b3ac;
$secondary-color: #222d32;
$secondary-variant-color: #394247;
$border-color: #e7ecf2;
$placeholder-color: #bcc0c1;
$field-color: #66737f;
$background-color: #ecf0f5;
$success-color: #00a65a;
$success-variant-color: #00a65a2e;
$info-color: #f39c12;
$tab-text-color: #ffffff;

