.verify-box {
    background-color: #fff;
    border-radius: 10px;
    padding: 56px;
    text-align: center;
    max-width: 553px;
    margin: 24px;

    h1 {
        font-size: 29px;
        font-weight: 600;
    }

    p {
        font-size: 19px;
        margin-top: 12px;
        padding: 0 10px;
    }

    .info {
        font-size: 14px;
    }

    @media screen and (max-width: 525px) {
        padding: 50px 30px;

        h1 {
            font-size: 24px;

        }

        p {
            font-size: 16px;

        }
    }
}

.verifying-box {
    flex-direction: column;
    text-align: center;
    padding: 14px;

    h1 {
        font-size: 29px;
        font-weight: 600;
        color: #fff;
    }

    p {
        max-width: 400px;
        font-size: 16px;
        margin-top: 12px;
        padding: 0 10px;
        color: #fff;
    }
}